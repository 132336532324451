import { Component } from 'react';
import { Button, DatePicker, InputNumber, Modal, Table, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import TucuxiConfig from './TucuxiConfig.json';
import { Vapi } from '../services/Vapi'
import './BrousseauAndLee.css';

export default class BrousseauAndLee extends Component {

  constructor(props) {
    super(props);

    this.state = {
        count: 0,
        totalCount: 0,
        callList: [],
        callToView: null,
        waitingForResponse: false,

        queryParameters: {
            limit: '100',
            assistantId: TucuxiConfig.vapi.campaigns.BroussardAndLee.assistantId,
            createdAtGe: "2024-09-01T00:00:00Z",
            createdAtLe: null,
        }
    }
  }

  componentDidMount() {
    setTimeout(() => this.getCurrentCallList(), 1000);
  }

  getCurrentCallList() {
    if (this.state.waitingForResponse) {
        return;
    }

    this.setState( { waitingForResponse: true },
        () => Vapi.getCalls(data => this.handleCallListReceived(data), err => this.handleError(err), this.state.queryParameters));
  }

  handleCallListReceived(data) {
  /*
    call data example (one call in an array of calls):
    {
        "id": "90f7f9f3-181f-46eb-9da4-e3f36e273b1f",
        "assistantId": "48197c09-b2f6-4b7f-a7bf-03c76c9f3d25",
        "type": "webCall",
        "startedAt": "2024-09-30T19:34:35.326Z",
        "endedAt": "2024-09-30T19:35:29.956Z",
        "transcript": "AI: Hi, you've reached the law firm of Bruseau and Lee. I'm Kevin, a receptionist for Bruseau and Lee. How can I help you today?\nUser: Dunde asked to\nAI: It seems like there might have been a misunderstanding Could you please clarify what you mean by Dunde Ask To? Are you looking for specific information or assistance regarding an immigration matter?\nUser: Hola, habla español,\nAI: Y ¿En qué puedo ayudarte hoy?\nUser: Hola, Dunde S2.\nAI: Parece que hay un poco de confusión. Podrías darme más información estoy aquí para ayudar,\n",
        "recordingUrl": "https://auth.vapi.ai/storage/v1/object/public/recordings/90f7f9f3-181f-46eb-9da4-e3f36e273b1f-1727724932464-73ea75c6-b6c2-4ff3-8bf8-72e46cb2ea19-mono.wav",
        "summary": "Thank you for providing the transcript. After carefully reviewing it multiple times, I can summarize the key points as follows:\n\n1. The call begins with Kevin, a receptionist at the law firm of Bruseau and Lee, answering the phone in English.\n\n2. The caller's initial response is unclear, mentioning \"Dunde asked to\" which causes confusion.\n\n3. When asked for clarification, the caller switches to Spanish, asking if Spanish is spoken.\n\n4. The receptionist responds in Spanish, asking how they can help.\n\n5. The caller then says \"Hola, Dunde S2\" which again causes confusion.\n\n6. The receptionist, still speaking Spanish, acknowledges the confusion and asks for more information to better assist the caller.\n\nIn summary, this appears to be a call with significant communication difficulties, possibly due to a language barrier. The caller seems to be Spanish-speaking and may be trying to reach someone named \"Dunde\" or inquire about something related to \"S2\", but the exact nature of their request remains unclear from this transcript.",
        "createdAt": "2024-09-30T19:34:33.399Z",
        "updatedAt": "2024-09-30T19:35:42.140Z",
        "orgId": "520ec484-c000-4cc2-a2ca-ce6829898b7d",
        "cost": 0.0769,
        "webCallUrl": "https://vapi.daily.co/WK7z4a2nMBWOojlGoaHK",
        "status": "ended",
        "endedReason": "customer-ended-call",
        "messages": [],
        "stereoRecordingUrl": "https://auth.vapi.ai/storage/v1/object/public/recordings/90f7f9f3-181f-46eb-9da4-e3f36e273b1f-1727724932465-d7c45ca2-8333-4644-be0b-b004723cea8f-stereo.wav",
        "costBreakdown": {},
        "analysis": {},
        "artifact": {},
        "costs": [],
        "monitor": {
            "listenUrl": "wss://aws-us-west-2-production3-phone-call-websocket.vapi.ai/90f7f9f3-181f-46eb-9da4-e3f36e273b1f/listen",
            "controlUrl": "https://aws-us-west-2-production3-phone-call-websocket.vapi.ai/90f7f9f3-181f-46eb-9da4-e3f36e273b1f/control"
        }
    }
  */

    if (data) {
        this.setState({ count: data.length, totalCount: data.length, callList: data, waitingForResponse: false }, () =>
            setTimeout(() => this.getCurrentCallList(), TucuxiConfig.vapi.callListPollPeriod));
    } else {
        console.log('Response contained no data!');
    }
  }

  handleError(err) {
    console.log(err);
  }

  viewCallSummary(call) {
    this.setState({ callToView: call });
  }

  analyzeCall(call) {
    console.log('analyzeCall: ', call);
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleString();
  }

  formatDuration(duration) {
    const minutes = Math.floor(duration / 60000);
    const seconds = Math.floor(duration / 1000) - minutes * 60;
    return (minutes ? (minutes < 10 ? '0' : '') + minutes : '00') + ':' + (seconds ? (seconds < 10 ? '0' : '') + seconds : '00');
}

  renderCallTable(data) {
    const dataSource = [];
    if (data) {
        data.forEach(c => {
            const duration = new Date(c.endedAt) - new Date(c.startedAt);
            dataSource.push({
                key: c.id,
                date: c.startedAt,
                duration,
                type: c.type,
                summary: c.summary,
                recordingUrl: c.stereoRecordingUrl,
                endedReason: c.endedReason,
                status: c.status,
            });
        });
    }

    const columnDefs = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: text => this.formatDate(text),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: text => this.formatDuration(text),
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        //     key: 'type',
        // },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_, rec) => (
                <div className='incoming-calls-table-actions'>
                    <button className='calls-table-action' onClick={() => this.viewCallSummary(rec)}>Summary</button>
                    <button className='calls-table-action' onClick={() => window.open(rec.recordingUrl, '_blank')}>Recording</button>
                    <button className='calls-table-action' onClick={() => this.analyzeCall(rec)}>Analyze</button>
                </div>
            )
        },
    ];

    return <Table dataSource={dataSource} columns={columnDefs} />;

  }

  formatSummary(summary) {
    const summaryItems = [];
    if (summary) {
        let nextstart = 0;
        while (nextstart >= 0) {
            const nextStop = summary.indexOf('\n', nextstart + 1);
            if (nextStop > 0) {
                summaryItems.push(<p>{summary.substring(nextstart, nextStop)}</p>);
                nextstart = nextStop;
            } else {
                summaryItems.push(summary.substring(nextstart));
                nextstart = -1;
            }
        }

        return <div>{summaryItems}</div>;
    }

    return '';
  }

  render() {
    const call = this.state.callToView;
    return (<>
        <div className='tucuxi-app-header'>
            <a href='/'>
                <img 
                    id='tucuxi-app-header-logo'
                    src='images/cropped-tucuxi_color_logo-2-transparent-300x133.png'
                    alt='tucuxi.ai logo'
                />
            </a>
            <div className='header-center-content'>
                {TucuxiConfig.vapi.campaigns.BroussardAndLee.name} Receptionist Portal
            </div>
        </div>
        <div className='incoming-calls-body'>
            <div className='incoming-calls-table-header'>
                <Tooltip title="Refresh the call list" color='blue' placement='bottomRight'>
                    <Button type="primary" shape="circle" icon={<ReloadOutlined />} size='small' onClick={() => this.getCurrentCallList()} />
                </Tooltip>
                <div className='incoming-calls-table-title'>
                    Incoming Calls ({this.state.totalCount} total):
                </div>
            </div>
            <div className='incoming-calls-table-wrapper'>
                {this.renderCallTable(this.state.callList)}
            </div>
        </div>
        <div className='incoming-calls-filters'>
            <span className='filter-label'>Showing calls from:</span>
            <DatePicker
                defaultValue={this.state.queryParameters.createdAtGe && dayjs(this.state.queryParameters.createdAtGe)}
                onChange={v => v && this.setState({ queryParameters: { ...this.state.queryParameters, createdAtGe: v.toISOString() } })}
                showTime={true}
            />
            <span className='filter-label'>to:</span>
            <DatePicker
                defaultValue={this.state.queryParameters.createdAtLe && dayjs(this.state.queryParameters.createdAtLe)}
                onChange={v => v && this.setState({ queryParameters: { ...this.state.queryParameters, createdAtLe: v.toISOString() } })}
                showTime={true}
            />
            <span className='filter-label'>limit:</span>
            <InputNumber
                onChange={v => v && this.setState({ queryParameters: { ...this.state.queryParameters, limit: v } })}
                value={this.state.queryParameters.limit}
            />
        </div>
        <Modal
            footer={null}
            title={call && call.id}
            open={call}
            onOk={() => this.setState({ callToView: null })}
            onCancel={() => this.setState({ callToView: null })}
        >
            <p>{call && this.formatSummary(call.summary)}</p>
        </Modal>
    </>);
  }
}
