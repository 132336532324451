import vapi from "./vapiConfig.json";

/**
 * 
 * @param {*} responseCallback 
 * @param {*} errorCallback 
 * @param {*} callId
 */
const getCall = (responseCallback, errorCallback, callId) => {
    const options = {method: 'GET', headers: {authorization: vapi.apiKey}};

    let url = vapi.baseUrl + 'call/' + callId;
    console.log('Using url: ' + url);

    fetch(url, options)
        .then(response => response.json())
        .then(response => responseCallback(response))
        .catch(err => errorCallback(err));
}

/**
 * Query parameters:
 * assistantId (string)
 * This will return calls with the specified assistantId.
 *
 * limit (number)
 *    This is the maximum number of items to return. Defaults to 100.
 *
 * createdAtGt (string)
 *    This will return items where the createdAt is greater than the specified value.
 *
 * createdAtLt (string)
 *    This will return items where the createdAt is less than the specified value.
 *
 * createdAtGe (string)
 *    This will return items where the createdAt is greater than or equal to the specified value.
 *
 * createdAtLe (string)
 *    This will return items where the createdAt is less than or equal to the specified value.
 *
 * updatedAtGt (string)
 *    This will return items where the updatedAt is greater than the specified value.
 *
 * updatedAtLt (string)
 *    This will return items where the updatedAt is less than the specified value.
 *
 * updatedAtGe (string)
 *    This will return items where the updatedAt is greater than or equal to the specified value.
 *
 * updatedAtLe (string)
 *    This will return items where the updatedAt is less than or equal to the specified value.
 * 
 * @param {*} responseCallback 
 * @param {*} errorCallback 
 * @param {*} queryParameters 
 */
const getCalls = (responseCallback, errorCallback, queryParameters) => {
    const options = {method: 'GET', headers: {authorization: vapi.apiKey}};

    let url = vapi.baseUrl + 'call';
    let param = 0;
    Object.keys(queryParameters)
        .filter(k => queryParameters[k] !== null)
        .forEach(k => url += ((++param === 1 ? '?': '&') + k + '=' + queryParameters[k]));
    console.log('Using url: ' + url);

    fetch(url, options)
        .then(response => response.json())
        .then(response => responseCallback(response))
        .catch(err => errorCallback(err));
}

export const Vapi = {
    getCall,
    getCalls
};
